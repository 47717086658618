.time-up{
    opacity: 0.5;
    transform: translateY(-180%);
    transition: all 0.4s;
    font-size: 18px;
}

.time-up-bottom{
    color: black;
    transform: translateY(-177%);
    transition: all 0.4s;
    font-size: 20px;
}

.d-none-custom{
    display: none!important;
    position: absolute;
}

.delay{
    transition: all 0.4s;
    opacity: 1;
}

#pointer {
    height: 21px;
    position: relative;
    background: #0060a5;
}
#pointer:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
#pointer:before {
    content: "";
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid var(--primary-color);
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
}

#pointer2 {
    height: 26px;
    position: relative;
    background: #0060a5;
}
#pointer2:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
#pointer2:before {
    content: "";
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid var(--primary-color);
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
}

#pointer3 {
    height: 23px;
    position: relative;
    background: #0060a5;
}
#pointer3:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
#pointer3:before {
    content: "";
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid var(--primary-color);
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
}

