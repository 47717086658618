.image-picker-edit {
    position: absolute;
    left: -10px;
    bottom: -5px;
}

.image-picker-undo {
    position: absolute;
    right: -10px;
    bottom: -5px;
}

.image-input-circle .image-picker-edit {
    right: 0;
    bottom: 0;
}