.h1-CDCT {
    text-align: center;
    margin-bottom: 40px;
}

.timer-wrapper-CDCT {
    display: flex;
    justify-content: center;
}

.timer-CDCT {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-CDCT {
    color: #aaa;
}

.value-CDCT {
    font-size: 40px;
}

.value-CDCT-light {
    font-size: 14px;
}

.info-CDCT {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
}
