.compteurSplitPresentation{
    font-size: 40px;
    font-weight:bold;
    border-radius:10px;
    width: 45px;
    padding-top: 5px;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    height: 70px;
    border: 3px solid var(--primary-color);
}