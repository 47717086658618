.outer-container {
    box-sizing: border-box;
}
.carousel-item-custom {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    top: 50px;
    transition: transform 0.4s ease, opacity 0.4s ease;
}
.carousel-item-custom.visible {
    opacity: 1;
}
