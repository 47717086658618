.MetaTable {
    table {
        position: relative;
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        border-spacing: 0;
        isolation: isolate;
        white-space: nowrap;
    }
      
    tbody::before {
        display: block;
        padding-top: var(--virtualPaddingTop);
        content: "";
    }
      
    tbody::after {
        display: block;
        padding-bottom: var(--virtualPaddingBottom);
        content: "";
    }

    thead > tr > th {
        margin: 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .resizer {
        touch-action: none;
    }
}

.cursor-not-allowed {
    cursor: not-allowed;
}