/* tiny-mce */
.mce-content-body {
    min-height: 100px;
    padding: 10px;
    margin: 5px;
}

/* Select 2 */

.select2-selection {
    height: 35px !important;
}
