.contain {
    width : 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box {
    background: white;
    /*min-height: 560px;*/
    width: 680px;
    border-radius: 0 0 10px 10px;
    /*padding-top: 20px;*/
    padding-bottom: 0px;

    overflow: hidden;
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
    border: 1px solid #cccccc;
    box-shadow: 2px 2px 5px rgba(68, 68, 68, 0.2);
}

.albumName {
    display: flex;
    padding-bottom: 10px;
    height: fit-content;
}

.albumNameP {
    font-size: 15px;
    padding : 0;
    margin : 0;
}

.albumNameB {
    font-size: 15px;
    color: #363636;
}

.h4 {
    padding-bottom: 4px;
    cursor: pointer;
}

.h4:hover,
.b:hover {
    text-decoration: underline;
}

.iconBack {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.iconBack:hover {
    background: #f2f2f2;
}

.dateIco {
    display: flex;
    align-items: center;
    gap: 2px;
}

.dpImgImg {
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.p {
    font-size: 12px;
    color: #898a8d;
}

.dpImg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.parentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 15px;
}

.cover {
    display: flex;
    flex-wrap: wrap;
}

.coverImg {
    width: 100%;
    border-radius: none;
    cursor: pointer;
    object-fit: cover;
}

.react {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.reactP {
    font-size: 15px;
    cursor: pointer;
}

.reactP:hover {
    text-decoration: underline;
}

.bottomIcon {
    padding: 6px 100px;
    display: flex;
    justify-content: space-between;
}

.bottomIconP {
    font-size: 14px;
    font-weight: bold;
    color: #6e757d;
}

.makeHorizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.input {
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
}

.commentSec {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0px;
    gap: 5px;
}

.inputDiv {
    background: #f0f2f5;
    width: 600px;
    padding: 0px 10px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-radius: 10000px;
}

.commentSecImg {
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.commentSecSvg:hover {
    cursor: pointer;
}

.hr {
    border: 1px solid #e4e6eb;
}

.makeHorizontal {
    width: 150px;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
}

.makeHorizontal:hover {
    background: #f2f2f2;
}
