/* tiny-mce */
.mce-content-body {
    min-height: 100px;
}

/* Select 2 */

.select2-selection {
    height: 35px !important;
}
