.tox.tox-tinymce-inline .tox-editor-header {
    z-index: 4000;
}
.mce-content-body {
    min-height: 0px;
    margin: 0;
}
/* tiny-mce */
.mce-content-body {
    padding: 0;
}
#richText.textContent{
    padding-bottom: 0;
}