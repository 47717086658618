.section {
    display: inline-flex;
}

.buttonsWrapper {
    flex: 1;
}

.sectionButton {
    display: block;
    margin: 5px;
}