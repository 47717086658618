.canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.empty-canvas {
    height: 300px;
    background-image: url('../../assets/empty-canvas.svg');
    background-position: center;
    background-repeat: no-repeat;
}