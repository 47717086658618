.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    background-color: var(--primary-color);
}